<template>
  <div>
    <div>
      <div class="row justify-content-end mt-3">
        <div class="col-md-3">
          <div class="d-flex align-items-cener mb-3">
            <div class="input-group-append">
              <span class="bgBg py- px-3 border border-dark border-right-0">
                <i class="fa fa-search text-white lead pt-2"></i>
              </span>
            </div>
            <input
              class="border-left-0"
              placeholder="Search"
              v-model="search"
              type="search"
              prepend
            />
          </div>
        </div>
      </div>
      <v-data-table
        :items-per-page="6"
        :headers="headers"
        :items="users"
        :search="search"
        item-key="name"
      >
        <!-- style="height: 70px" -->
        <template v-slot:body="{ items, headers }">
          <tbody name="list" is="transition-group" v-if="items.length > 0">
            <tr v-for="(item, index) in items" :key="index" class="item-row">
              <td>
                <span class="p d-block mb-0">
                  {{ item.createdAt | formartDate }}</span
                >
              </td>
              <td>{{ item.userId }}</td>
              <td>
                <a :href="'mailto:' + item.email" class="text-black">
                  {{ item.email }}
                </a>
              </td>

              <td class="">
                <a :href="'tel:+234' + item.phoneNumber" class="text-black">
                  +234{{ item.phoneNumber }}
                </a>
              </td>

              <td>
                <router-link :to="'/all_users/' + item.userId">
                  <span>view</span>
                </router-link>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                v-if="!loading"
                :colspan="headers.length"
                style="text-align: center"
              >
                No Users Yet!
              </td>
              <td v-else style="text-align: center">
                Loading...
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";
import Calls from "@/Calls";
export default {
  name: "Users",
  components: {},
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Reg Date",
          align: "start",
          value: "reg_date",
        },
        {
          text: "userId",
          value: "userId",
        },
        {
          text: "Email",
          value: "email",
        },

        {
          text: "Phone No.",
          value: "phoneNumber",
        },
        { text: "View" },
      ],
      users: [],
      loading: true,
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await UserService.getUsers();
        this.users = response.data.rows.reverse();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.fetchUsers();
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
};
</script>
